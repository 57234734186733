<template>
  <div :class="[widgetClasses, `bg-${color}`]" class="card border border-gray-300 rounded h-100 flex-fill">
    <!--begin::Body-->
    <div class="card-body">

      <apexchart v-if="chartSeries"
                 class="statistics-widget-3-chart card-rounded-bottom mw-75 m-auto"
                 :options="chartOptions"
                 :series="chartSeries"
                 :height="50"
                 :width="100" />

      <div class="fw-bolder fs-1 text-center mt-4">
        {{ value }}
      </div>

      <div class="fw-bold fs-6 text-uppercase text-gray-600 text-center mt-2">
        {{ title }}
      </div>

      <div v-if="diff != null"
           :class="`text-${getDiffColor}`"
           class="fs-6 text-center mt-2 fw-bolder">
        <span v-if="diff > 0" class="svg-icon svg-icon-5 svg-icon-success ms-1">
          <inline-svg src="/media/svg/icons/Navigation/Arrow-up.svg" />
        </span>
        <span v-if="diff < 0" class="svg-icon svg-icon-5 svg-icon-danger ms-1">
          <inline-svg src="/media/svg/icons/Navigation/Arrow-down.svg" />
        </span>
        {{ diffPercent }} %
      </div>

    </div>
    <!--end::Body-->
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue";


export default defineComponent({
  name: 'MetricChartCard',
  props: {
    widgetClasses: String,
    color: String,
    iconColor: String,
    svgIcon: String,
    title: String,
    value: Number,
    diff: {
      type: [ Number ],
      required: false,
    },
    numberType: {
      type: String,
      default: 'integer'
    },
    chartData: Array as PropType<Array<number>>
  },

  computed: {
    chartSeries(): null|Record<any, any>{
      if(!this.chartData) return null;
      return [{
        data: this.chartData
      }]
    },
    getDiffColor(){
      return (this.diff ?? 0) > 0 ? 'success' : 'danger'
    },
    diffPercent(): null|number {
      if (this.diff == null) return null;
      return Math.round(this.diff * 100)
    }
  },

  components: {
  },

  data() {
    return {
      chartOptions: {
        chart: {
          fontFamily: "inherit",
          type: "line",
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          },
          sparkline: {
            enabled: true
          }
        },
        plotOptions: {},
        legend: {
          show: false
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: "smooth",
          show: true,
          width: 2,
        },
        tooltip: {
          enabled: false
        },
      }
    }
  }
});
</script>
