
import {defineComponent} from "vue";
// import MetricCard from "@/components/cards/MetricCard.vue";
import MetricChartCard from "@/components/cards/MetricChartCard.vue";
import DatePresetsDropdown from "@/components/dropdown/DatePresetsDropdown.vue";
import DashboardAutomationStatus from './AutomationStatus.vue';
import {mapActions, mapGetters, mapMutations} from "vuex";
import BaseMetricValue from "@/views/stats/metrics/BaseMetricValue.vue";

export default defineComponent({
  name: "Metrics",

  components: {
    // MetricCard,
    MetricChartCard,
    DatePresetsDropdown,
    DashboardAutomationStatus,
  },

  data() {
    return {
      // metrics: [
      //   {
      //     title: 'ROAS',
      //     value: 2.15,
      //     // diff: 0.37,
      //     // data: [1.8, 2.34, 2.13, 1.90, 2.66, 1.4, 2.19]
      //   },
      // ]
    };
  },

  computed: {
    ...mapGetters({
      selectedShopifyShop: 'shopify/shops/selected',
      keyMetrics: 'shopify/shops/dashboard/keyMetrics',
      keyMetricsAreFetching: 'shopify/shops/dashboard/keyMetricsAreFetching',
      keyMetricsPeriod: 'shopify/shops/dashboard/keyMetricsPeriod',
      keyMetricsValueIdentifiers: 'shopify/shops/dashboard/keyMetricsValueIdentifiers',
      metricsGlossary: 'automation/glossary/metrics/list',
    }),

    datePreset: {
      get(): string {
        return this.keyMetricsPeriod ?? 'today';
      },
      set(value) {
        this.setKeyMetricsPeriod(value);
        this.fetchKeyMetrics();
      }
    },

    metrics(): any[] {
      if(!this.keyMetrics || !this.keyMetricsValueIdentifiers || !this.metricsGlossary) return [];
      return Object.entries(this.keyMetricsValueIdentifiers).map((item: any[]) => {
        const alias = item[0];
        const metricIdentifier = item[1];
        const metricType = metricIdentifier.type;
        const valueIdentifier = metricIdentifier.valueIdentifier;

        const metric = (metricType && metricType in this.metricsGlossary)
          ? this.metricsGlossary[metricType]
          : {};
        const metricData = this.keyMetrics.find(keyMetricItem => keyMetricItem.valueIdentifier === valueIdentifier);


        const metricValue = metricData?.value ?? 0;
        const metricLabel = metric?.labels?.abbreviation ?? metric?.labels?.short ?? metric?.labels?.default ?? alias;

        return {
          alias,
          valueIdentifier,
          metric,
          metricData,
          metricValue,
          metricLabel,
        };
      });
    },

    // spend() {
    //   return this.keyMetrics?.total_spend ?? 0;
    // },
    //
    // sales() {
    //   return this.keyMetrics?.total_sales ?? 0;
    // },
    //
    // roas() {
    //   return this.keyMetrics?.roas ?? 0;
    // },
    //
    //
    // adCost() {
    //   const total_spend = this.keyMetrics?.total_spend ?? 0;
    //   const total_sales = this.keyMetrics?.total_sales ?? 0;
    //   return (total_spend === 0 || total_sales === 0) ? 0 : total_spend / total_sales;
    // }
  },

  methods: {
    ...mapActions({
      fetchKeyMetrics: 'shopify/shops/dashboard/fetchKeyMetrics',
      fetchMetricsGlossary: 'automation/glossary/metrics/fetch',
    }),
    ...mapMutations({
      setKeyMetricsPeriod: 'shopify/shops/dashboard/setKeyMetricsPeriod'
    }),
  },

  mounted() {
    this.fetchMetricsGlossary();
  },


});
