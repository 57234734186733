
import {defineComponent} from "vue";
import {mapGetters} from "vuex";
import Filters from "@/core/filters";

export default defineComponent({
  name: "UtmTable",
  components: {},
  props: {
    widgetClasses: String
  },

  computed: {
    ...mapGetters({
      stats: 'shopify/shops/dashboard/stats',
      statsAreFetching: 'shopify/shops/dashboard/statsAreFetching',
    }),

    list() {
      const UtmSources = this?.stats?.UtmSources;
      if(!UtmSources || !Array.isArray(UtmSources)) return [];

      // {"utm_source":"ig","sum__revenue":"22.770000000000003"}

      const sourceToIconMap = {
        fb: "/media/svg/brand-logos/facebook.svg",
        ig: "/media/svg/social-logos/instagram.svg",
        default: '/media/svg/icons/Code/Question-circle.svg'
      }

      return UtmSources
        .map(function (row) {
          return {
            utm: {
              icon: row.utm_source in sourceToIconMap ? sourceToIconMap[row.utm_source] : sourceToIconMap.default,
              source: row.utm_source,
              medium: row.utm_medium
            },
            sales: Filters.money(row.sum__revenue, 2)
          }
        });

      return [
        {
          "utm": {
            "icon": "/media/svg/brand-logos/facebook.svg",
            "source": "Facebook",
            "medium": "PPC"
          },
          "sales": '$' + (500 + Math.random() * 1000).toFixed(2),
        },
        {
          "utm": {
            "icon": "/media/svg/social-logos/instagram.svg",
            "source": "Facebook",
            "medium": "PPC"
          },
          "sales": '$' + (500 + Math.random() * 1000).toFixed(2),
        },
        {
          "utm": {
            "icon": "/media/svg/social-logos/youtube.svg",
            "source": "Facebook",
            "medium": "PPC"
          },
          "sales": '$' + (500 + Math.random() * 1000).toFixed(2),
        },
        {
          "utm": {
            "icon": "/media/svg/social-logos/twitter.svg",
            "source": "Facebook",
            "medium": "PPC"
          },
          "sales": '$' + (500 + Math.random() * 1000).toFixed(2),
        },
      ]
    }
  },

});
