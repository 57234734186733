
import { defineComponent } from "vue";
import {mapGetters} from "vuex";

export default defineComponent({
  name: "CampaignTable",
  components: {},
  props: {
    widgetClasses: String
  },

  computed: {

    ...mapGetters({
      stats: 'shopify/shops/dashboard/stats',
      statsAreFetching: 'shopify/shops/dashboard/statsAreFetching',
    }),

    list() {
      const campaignsData = this?.stats?.Campaign;
      if(!campaignsData || !Array.isArray(campaignsData)) return [];

      /*
      {
  "id: 123123123,
  "name": "consectetur enim voluptatem",
  "objective": "APP_INSTALLS",
  "effective_status": "PAUSED",
  "today_fb_spend": null,
  "today_fb_roas": null,
  "today_utm_roas": null,
  "today_fb_cpa": null,
  "today_utm_cpa": null,
  "l7d_fb_spend": "297.66",
  "l30d_fb_spend": "1435.85"
}
       */

      return campaignsData
        .map(function (row) {
          return {
            campaign: {
              id: row.id,
              name: row.name,
              objective: row.objective,
            },
            today_fb_spend: row.today_fb_spend,
            today_fb_roas: row.today_fb_roas,
            today_utm_roas: row.today_utm_roas,
            today_fb_cpa: row.today_fb_cpa,
            today_utm_cpa: row.today_utm_cpa,
            l7d_fb_spend: row.l7d_fb_spend,
            l30d_fb_spend: row.l30d_fb_spend,
          }
        });

      return [
        {
          "product": {
            "image": "http://dummyimage.com/300x200.png/dddddd/000000",
            "title": "3-OAK MIX, BLACK/BLACKJACK/POST POLLEN",
            "type": "940"
          },
          "spend": 7.7,
          "sales": 94.69,
          "budget": 59.84,
          "spend_l7d": 23.38,
          "budget_l7d": 108.85,
          "spend_l30d": 1177.2,
          "budget_l30d": 1789.5
        },
        {
          "product": {
            "image": "http://dummyimage.com/300x200.png/ff4444/ffffff",
            "title": "BABY SUNSCREEN",
            "type": "Cougar"
          },
          "spend": 96.67,
          "sales": 46.5,
          "budget": 42.1,
          "spend_l7d": 285.6,
          "budget_l7d": 270.06,
          "spend_l30d": 1233.3,
          "budget_l30d": 2063.4
        },
        {
          "product": {
            "image": "http://dummyimage.com/300x200.png/dddddd/000000",
            "title": "Standardized Kentucky Blue (June) Grass",
            "type": "Bonneville"
          },
          "spend": 66.77,
          "sales": 73.21,
          "budget": 19.15,
          "spend_l7d": 88.97,
          "budget_l7d": 489.3,
          "spend_l30d": 711.9,
          "budget_l30d": 2572.5
        },
        {
          "product": {
            "image": "http://dummyimage.com/300x200.png/cc0000/ffffff",
            "title": "Formica Apis",
            "type": "Ram Van 1500"
          },
          "spend": 7.39,
          "sales": 19.53,
          "budget": 22.61,
          "spend_l7d": 400.47,
          "budget_l7d": 375.2,
          "spend_l30d": 368.7,
          "budget_l30d": 1211.4
        },
        {
          "product": {
            "image": "http://dummyimage.com/300x200.png/ff4444/ffffff",
            "title": "Virotox WN",
            "type": "Cherokee"
          },
          "spend": 48.77,
          "sales": 7.98,
          "budget": 52.76,
          "spend_l7d": 379.26,
          "budget_l7d": 87.99,
          "spend_l30d": 1407.0,
          "budget_l30d": 1791.9
        },
        {
          "product": {
            "image": "http://dummyimage.com/300x200.png/5fa2dd/ffffff",
            "title": "Heparin Sodium in Dextrose",
            "type": "Town & Country"
          },
          "spend": 9.05,
          "sales": 56.11,
          "budget": 2.43,
          "spend_l7d": 617.19,
          "budget_l7d": 680.54,
          "spend_l30d": 2653.2,
          "budget_l30d": 723.6
        },
        {
          "product": {
            "image": "http://dummyimage.com/300x200.png/ff4444/ffffff",
            "title": "Levothyroxine Sodium",
            "type": "Trooper"
          },
          "spend": 14.6,
          "sales": 37.83,
          "budget": 35.85,
          "spend_l7d": 644.42,
          "budget_l7d": 561.4,
          "spend_l30d": 2501.7,
          "budget_l30d": 2317.2
        },
        {
          "product": {
            "image": "http://dummyimage.com/300x200.png/5fa2dd/ffffff",
            "title": "Suphedrine PE",
            "type": "Sonata"
          },
          "spend": 29.99,
          "sales": 59.14,
          "budget": 70.9,
          "spend_l7d": 566.65,
          "budget_l7d": 550.83,
          "spend_l30d": 416.7,
          "budget_l30d": 1263.0
        },
        {
          "product": {
            "image": "http://dummyimage.com/300x200.png/ff4444/ffffff",
            "title": "MANGO BUTTER LIPSTICK",
            "type": "Suburban 2500"
          },
          "spend": 63.57,
          "sales": 2.68,
          "budget": 18.02,
          "spend_l7d": 554.33,
          "budget_l7d": 47.81,
          "spend_l30d": 2908.8,
          "budget_l30d": 2342.1
        },
        {
          "product": {
            "image": "http://dummyimage.com/300x200.png/cc0000/ffffff",
            "title": "Reboost",
            "type": "Econoline E150"
          },
          "spend": 69.36,
          "sales": 33.94,
          "budget": 46.88,
          "spend_l7d": 283.85,
          "budget_l7d": 357.49,
          "spend_l30d": 1326.3,
          "budget_l30d": 1421.4
        }
      ];
    }
  },

});
