
import {defineComponent} from "vue";
import {mapGetters} from "vuex";

export default defineComponent({
  name: "InvalidShop",

  computed: {
    ...mapGetters({
      selectedShopifyShop: 'shopify/shops/selected',
    }),
  }
});
