<template>
  <b-card body-class="text-center" bg-variant="dark">
    <span v-if="!selectedShopifyShop" class="text-warning h4">Please select shop</span>
    <span v-if="selectedShopifyShop && (!selectedShopifyShop.facebookAdAccounts || !selectedShopifyShop.facebookAdAccounts.length)" class="text-warning h4">
          Please <router-link :to="{name: 'integration.shopify'}">link</router-link> Facebook Ad Account to the shop "{{ selectedShopifyShop.name }}"
        </span>
  </b-card>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {mapGetters} from "vuex";

export default defineComponent({
  name: "InvalidShop",

  computed: {
    ...mapGetters({
      selectedShopifyShop: 'shopify/shops/selected',
    }),
  }
});
</script>
