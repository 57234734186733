
import { defineComponent } from "vue";
import { getCSSVariableValue } from "@/assets/ts/_utils";
import Filters from "@/core/filters";
import {mapGetters} from "vuex";

export default defineComponent({
  name: "SalesByHour",
  props: {
    widgetClasses: String
  },
  components: {},

  computed: {

    ...mapGetters({
      stats: 'shopify/shops/dashboard/stats',
      statsAreFetching: 'shopify/shops/dashboard/statsAreFetching',
    }),

    chartSeries() {
      const salesByHour = this?.stats?.SalesByHour;
      if(!salesByHour) return []; // todo: assert it's an object

      return [
        {
          name: 'Same Day Week Ago',
          type: 'line',
          data: salesByHour['Week Ago']
        },
        {
          name: "Yesterday",
          type: 'line',
          data: salesByHour?.Yesterday
        },
        {
          name: "Today",
          type: 'line',
          data: salesByHour?.Today
        }
      ];
    },

    chartOptions() {
      const labelColor = getCSSVariableValue("--bs-gray-500");
      const borderColor = getCSSVariableValue("--bs-gray-200");
      const baseColor = getCSSVariableValue("--bs-info");
      const lightColor = getCSSVariableValue("--bs-light-info");

      return {
        chart: {
          type: 'line',
          fontFamily: "inherit",
          height: 350,
          toolbar: {
            show: false
          },
          colors : ['#4d3a96', '#4576b5'],
        },
        xaxis: {
          type: 'category',
          tickAmount: 6,
          labels: {
            rotate: 0
          }
        },
        yaxis: {
          labels: {
            formatter: (val) => Filters.money(val),
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: [2, 2, 3],
          curve: ['smooth', 'smooth', 'smooth'],
          dashArray: [10,2,0]
        },
        fill: {
          type: 'solid',
        },
        colors: ['#cccccc', '#ffb703', '#7209b7'],
        grid: {
          borderColor: borderColor,
          strokeDashArray: 4,
          yaxis: {
            lines: {
              show: true
            }
          }
        }
      };
    },

  },
});
