<template>
  <div>
    <div v-if="automationMode" class="fs-6 text-nowrap">
      <span class="text-muted">Mode</span>
      <span class="ms-3 p-3 border rounded" :class="automationModeBorderColorClass">
        <i class="fas fa-circle" :class="automationModeTextColorClass" />
        <span class="ps-4">{{ automationModeLabel }}</span>
      </span>
    </div>

  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {mapGetters} from "vuex";

export default defineComponent({
  name: "AutomationStatus",

  computed: {
    ...mapGetters({
      selectedShop: 'shopify/shops/selected',
      options: 'shopify/dropdown/shops/automationStatuses/list',
      optionValueTextColorClassMap: 'shopify/dropdown/shops/automationStatuses/valueTextColorClassMap',
      optionValueBorderColorClassMap: 'shopify/dropdown/shops/automationStatuses/valueBorderColorClassMap',
    }),

    automationMode(): null|string {
      return this?.selectedShop?.automation_mode;
    },

    automationModeLabel(): null|string {
      if(!this.automationMode) return null;
      const option = this.options.find(option => option.value === this.automationMode);

      if(!option) return null;
      return option.label;
    },

    automationModeTextColorClass(): null|string {
      if(!this.automationMode) return null;
      if(!(this.automationMode in this.optionValueTextColorClassMap)) return null;

      return this.optionValueTextColorClassMap[this.automationMode];
    },

    automationModeBorderColorClass(): null|string {
      if(!this.automationMode) return null;
      if(!(this.automationMode in this.optionValueBorderColorClassMap)) return null;

      return this.optionValueBorderColorClassMap[this.automationMode];
    }
  },

});
</script>

<style scoped>

</style>