
import {defineComponent} from "vue";
import {mapActions, mapGetters} from "vuex";
import { ListItem } from '@/store/modules/automation/dropdown/datePresets/state'

export default defineComponent({
  name: "DatePresetsDropdown",

  props: {
    value: {
      default: null,
    },
    disabledValues: {
      type: Array,
      default: null,
    },
    placeholder: {
      type: String,
      default: 'Select Date Preset',
    },
  },

  data() {
    return {
      selection: null,
    };
  },

  computed: {
    ...mapGetters({
      dropdownList: 'automation/dropdown/datePresets/list',
      dropdownListIsLoading: 'automation/dropdown/datePresets/listIsFetching',
    }),

    dropdownOptions(): ListItem[] {
      if(this.dropdownListIsLoading) return [];
      return this.dropdownList;
    },

  },

  methods: {
    ...mapActions({
      fetchDropdownList: 'automation/dropdown/datePresets/fetch',
    }),

    selectionChanged(value) {
      this.$emit('update:value', value);
    },
  },

  mounted() {
    this.selection = this.value;
    this.fetchDropdownList();
  }

});
