
import { defineComponent } from "vue";
import { getCSSVariableValue } from "@/assets/ts/_utils";
import Filters from "@/core/filters";
import {mapGetters} from "vuex";

export default defineComponent({
  name: "TopSources",
  props: {
    widgetClasses: String
  },
  components: {},

  computed: {

    ...mapGetters({
      stats: 'shopify/shops/dashboard/stats',
      statsAreFetching: 'shopify/shops/dashboard/statsAreFetching',
    }),

    chartSeries() {
      const TopSources = this?.stats?.TopSources;
      if(!TopSources || !Array.isArray(TopSources)) return [];

      return TopSources
        .map(function (row) {
          return {
            name: row.utm_source,
            data: row.data
          }
        });
    },

    chartOptions() {
      const labelColor = getCSSVariableValue("--bs-gray-500");
      const borderColor = getCSSVariableValue("--bs-gray-200");
      const baseColor = getCSSVariableValue("--bs-primary");
      const secondaryColor = getCSSVariableValue("--bs-gray-300");

      return {
        chart: {
          fontFamily: "inherit",
          type: "bar",
          toolbar: {
            show: false
          }
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: ["50%"],
            endingShape: "rounded"
          }
        },
        legend: {
          show: true
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"]
        },
        xaxis: {
          type: 'datetime',
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          labels: {
            style: {
              colors: labelColor,
              fontSize: "12px"
            }
          }
        },
        yaxis: {
          labels: {
            formatter: (val) => Filters.shortMoney(val),
            style: {
              colors: labelColor,
              fontSize: "12px"
            }
          }
        },
        fill: {
          opacity: 1
        },
        states: {
          normal: {
            filter: {
              type: "none",
              value: 0
            }
          },
          hover: {
            filter: {
              type: "none",
              value: 0
            }
          },
          active: {
            allowMultipleDataPointsSelection: false,
            filter: {
              type: "none",
              value: 0
            }
          }
        },
        tooltip: {
          style: {
            fontSize: "12px"
          },
          y: {
            formatter: Filters.money
          }
        },
        grid: {
          borderColor: borderColor,
          strokeDashArray: 4,
          yaxis: {
            lines: {
              show: true
            }
          }
        }
      };
    },

  },
});
