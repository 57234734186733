<template>
  <b-overlay :show="statsAreFetching" rounded="sm">
  <div class="card" :class="widgetClasses">
    <div class="card-header border-0 pt-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label fw-bolder fs-3 mb-1">ROAS</span>
      </h3>
    </div>
    <div class="card-body">
      <apexchart type="line" :options="chartOptions" :series="chartSeries" />
    </div>
  </div>
  </b-overlay>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { getCSSVariableValue } from "@/assets/ts/_utils";
import {mapGetters} from "vuex";

export default defineComponent({
  name: "Roas",
  props: {
    widgetClasses: String
  },
  components: {
  },

  computed: {

    ...mapGetters({
      stats: 'shopify/shops/dashboard/stats',
      statsAreFetching: 'shopify/shops/dashboard/statsAreFetching',
    }),

    chartSeries() {
      const cpaRoasSales = this?.stats?.CpaRoasSales;
      if(!cpaRoasSales || !Array.isArray(cpaRoasSales)) return [];

      return [
        {
          name: "Blended",
          data: cpaRoasSales.filter(row => row?.blended_roas !== null).map(row => {return {x: row.date, y: row.blended_roas}}),
          type: 'line',
        },
        {
          name: "Facebook",
          data: cpaRoasSales.filter(row => row?.facebook_roas !== null).map(row => {return {x: row.date, y: row.facebook_roas}}),
          type: 'bar',
        },
        {
          name: "Algoma Pixel",
          data: cpaRoasSales.filter(row => row?.algoma_pixel_roas !== null).map(row => {return {x: row.date, y: row.algoma_pixel_roas}}),
          type: 'bar'
        },
      ];
    },

    chartOptions() {
      const labelColor = getCSSVariableValue("--bs-gray-500");
      const borderColor = getCSSVariableValue("--bs-gray-200");
      const baseColor = getCSSVariableValue("--bs-primary");
      const secondaryColor = getCSSVariableValue("--bs-gray-300");

      return {
        chart: {
          type: 'line',
          fontFamily: "inherit",
          toolbar: {
            show: false
          }
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '50%',
            endingShape: "rounded"
          }
        },
        fill: {
          opacity: 1
        },
        xaxis: {
          type: 'datetime',
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          labels: {
            style: {
              colors: labelColor,
              fontSize: "12px"
            }
          }
        },
        dataLabels: {
          enabled: false,
        },
        yaxis: {
          labels: {
            formatter: (val => val.toFixed(2)),
            style: {
              colors: labelColor,
              fontSize: "12px"
            }
          },
        },
      };
    },

  },

});
</script>
