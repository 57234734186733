<template>
  <b-overlay :show="statsAreFetching" rounded="sm">
    <div class="card" :class="widgetClasses">
      <div class="card-header border-0 pt-5">
        <h3 class="card-title align-items-start flex-column">
          <span class="card-label fw-bolder fs-3 mb-1">CPA</span>
        </h3>
      </div>
      <div class="card-body">
        <apexchart type="line" :options="chartOptions" :series="chartSeries"/>
      </div>
    </div>
  </b-overlay>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {getCSSVariableValue} from "@/assets/ts/_utils";
import Filters from "@/core/filters";
import {mapGetters} from "vuex";

export default defineComponent({
  name: "CPA",

  props: {
    widgetClasses: {
      type: String
    }
  },

  components: {},

  computed: {

    ...mapGetters({
      stats: 'shopify/shops/dashboard/stats',
      statsAreFetching: 'shopify/shops/dashboard/statsAreFetching',
    }),

    chartSeries() {
      const cpaRoasSales = this?.stats?.CpaRoasSales;
      if(!cpaRoasSales || !Array.isArray(cpaRoasSales)) return [];

      return [
        {
          name: "Blended",
          data: cpaRoasSales.filter(row => row?.blended_cpa !== null).map(row => { return {x: row.date, y: row.blended_cpa}; }),
          type: 'line',
        },
        {
          name: "Facebook",
          data: cpaRoasSales.filter(row => row?.facebook_cpa !== null).map(row => { return {x: row.date, y: row.facebook_cpa}; }),
          type: 'bar',
        },
        {
          name: "Algoma Pixel",
          data: cpaRoasSales.filter(row => row?.algoma_pixel_cpa !== null).map(row => { return {x: row.date, y: row.algoma_pixel_cpa}; }),
          type: 'bar'
        },
      ];
    },

    chartOptions() {
      const labelColor = getCSSVariableValue("--bs-gray-500");

      return {
        chart: {
          fontFamily: "inherit",
          toolbar: {
            show: false
          }
        },
        tooltip: {
          y: {
            formatter: (val) => '$' + Filters.shortMoney(val),
          }
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '50%',
            endingShape: "rounded"
          }
        },
        fill: {
          opacity: 1
        },
        xaxis: {
          type: 'datetime',
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          labels: {
            // todo: format as "Month Day" i.e. July 9
            // formatter: function(value, timestamp, opts) {
            //   return opts.dateFormatter(new Date(timestamp)).format("MMM dd")
            // },
            style: {
              colors: labelColor,
              fontSize: "12px"
            }
          }
        },
        yaxis: {
          labels: {
            formatter: (val) => Filters.shortMoney(val),
            style: {
              colors: labelColor,
              fontSize: "12px"
            }
          },
        },
      };
    },

  },

});
</script>
