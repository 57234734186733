<template>
  <b-overlay :show="statsAreFetching" rounded="sm">
  <div :class="widgetClasses" class="card">
    <div class="card-header border-0 pt-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label fw-bolder fs-3 mb-1">UTM Sources Stats</span>
      </h3>
      <div class="card-toolbar">
      </div>
    </div>
    <div class="card-body py-3">
      <div class="table-responsive">
        <table class="table align-middle gs-0 gy-4">
          <thead>
          <tr class="fw-bolder text-muted">
            <th class="min-w-200px rounded-start">Source</th>
            <th class="min-w-125px">Sales</th>
          </tr>
          </thead>
          <tbody>
          <template v-for="(item, index) in list" :key="index">
            <tr>
              <td>
                <div class="d-flex align-items-center">
                  <div class="symbol symbol-35px me-5">
                    <img :src="item.utm.icon"/>
                  </div>
                  <div class="d-flex justify-content-start flex-column">
                    <a
                      href="#"
                      class="text-dark fw-bolder text-hover-primary mb-1 fs-6"
                    >{{ item.utm.source }}</a
                    >
                    <span
                      class="text-muted fw-bold text-muted d-block fs-7"
                    >{{ item.utm.medium }}</span
                    >
                  </div>
                </div>
              </td>
              <td>
                <a
                  href="#"
                  class="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6"
                >{{ item.sales }}</a
                >
              </td>
            </tr>
          </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  </b-overlay>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {mapGetters} from "vuex";
import Filters from "@/core/filters";

export default defineComponent({
  name: "UtmTable",
  components: {},
  props: {
    widgetClasses: String
  },

  computed: {
    ...mapGetters({
      stats: 'shopify/shops/dashboard/stats',
      statsAreFetching: 'shopify/shops/dashboard/statsAreFetching',
    }),

    list() {
      const UtmSources = this?.stats?.UtmSources;
      if(!UtmSources || !Array.isArray(UtmSources)) return [];

      // {"utm_source":"ig","sum__revenue":"22.770000000000003"}

      const sourceToIconMap = {
        fb: "/media/svg/brand-logos/facebook.svg",
        ig: "/media/svg/social-logos/instagram.svg",
        default: '/media/svg/icons/Code/Question-circle.svg'
      }

      return UtmSources
        .map(function (row) {
          return {
            utm: {
              icon: row.utm_source in sourceToIconMap ? sourceToIconMap[row.utm_source] : sourceToIconMap.default,
              source: row.utm_source,
              medium: row.utm_medium
            },
            sales: Filters.money(row.sum__revenue, 2)
          }
        });

      return [
        {
          "utm": {
            "icon": "/media/svg/brand-logos/facebook.svg",
            "source": "Facebook",
            "medium": "PPC"
          },
          "sales": '$' + (500 + Math.random() * 1000).toFixed(2),
        },
        {
          "utm": {
            "icon": "/media/svg/social-logos/instagram.svg",
            "source": "Facebook",
            "medium": "PPC"
          },
          "sales": '$' + (500 + Math.random() * 1000).toFixed(2),
        },
        {
          "utm": {
            "icon": "/media/svg/social-logos/youtube.svg",
            "source": "Facebook",
            "medium": "PPC"
          },
          "sales": '$' + (500 + Math.random() * 1000).toFixed(2),
        },
        {
          "utm": {
            "icon": "/media/svg/social-logos/twitter.svg",
            "source": "Facebook",
            "medium": "PPC"
          },
          "sales": '$' + (500 + Math.random() * 1000).toFixed(2),
        },
      ]
    }
  },

});
</script>
