
import {defineComponent} from "vue";
import {mapGetters} from "vuex";

export default defineComponent({
  name: "AutomationStatus",

  computed: {
    ...mapGetters({
      selectedShop: 'shopify/shops/selected',
      options: 'shopify/dropdown/shops/automationStatuses/list',
      optionValueTextColorClassMap: 'shopify/dropdown/shops/automationStatuses/valueTextColorClassMap',
      optionValueBorderColorClassMap: 'shopify/dropdown/shops/automationStatuses/valueBorderColorClassMap',
    }),

    automationMode(): null|string {
      return this?.selectedShop?.automation_mode;
    },

    automationModeLabel(): null|string {
      if(!this.automationMode) return null;
      const option = this.options.find(option => option.value === this.automationMode);

      if(!option) return null;
      return option.label;
    },

    automationModeTextColorClass(): null|string {
      if(!this.automationMode) return null;
      if(!(this.automationMode in this.optionValueTextColorClassMap)) return null;

      return this.optionValueTextColorClassMap[this.automationMode];
    },

    automationModeBorderColorClass(): null|string {
      if(!this.automationMode) return null;
      if(!(this.automationMode in this.optionValueBorderColorClassMap)) return null;

      return this.optionValueBorderColorClassMap[this.automationMode];
    }
  },

});
