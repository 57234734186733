
import {defineComponent, PropType} from "vue";


export default defineComponent({
  name: 'MetricChartCard',
  props: {
    widgetClasses: String,
    color: String,
    iconColor: String,
    svgIcon: String,
    title: String,
    value: Number,
    diff: {
      type: [ Number ],
      required: false,
    },
    numberType: {
      type: String,
      default: 'integer'
    },
    chartData: Array as PropType<Array<number>>
  },

  computed: {
    chartSeries(): null|Record<any, any>{
      if(!this.chartData) return null;
      return [{
        data: this.chartData
      }]
    },
    getDiffColor(){
      return (this.diff ?? 0) > 0 ? 'success' : 'danger'
    },
    diffPercent(): null|number {
      if (this.diff == null) return null;
      return Math.round(this.diff * 100)
    }
  },

  components: {
  },

  data() {
    return {
      chartOptions: {
        chart: {
          fontFamily: "inherit",
          type: "line",
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          },
          sparkline: {
            enabled: true
          }
        },
        plotOptions: {},
        legend: {
          show: false
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: "smooth",
          show: true,
          width: 2,
        },
        tooltip: {
          enabled: false
        },
      }
    }
  }
});
