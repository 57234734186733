<template>
  <b-overlay :show="statsAreFetching" rounded="sm">
  <div class="card" :class="widgetClasses">
    <div class="card-header border-0 pt-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label fw-bolder fs-3 mb-1">Sales</span>
      </h3>
    </div>
    <div class="card-body">
      <apexchart type="line" :options="chartOptions" :series="chartSeries" />
    </div>
  </div>
  </b-overlay>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {getCSSVariableValue} from "@/assets/ts/_utils";
import Filters from "@/core/filters";
import {mapGetters} from "vuex";
import {filter} from "lodash";

export default defineComponent({
  name: "Sales",
  props: {
    widgetClasses: String
  },
  components: {},

  computed: {

    ...mapGetters({
      stats: 'shopify/shops/dashboard/stats',
      statsAreFetching: 'shopify/shops/dashboard/statsAreFetching',
    }),

    chartSeries() {
      const cpaRoasSales = this?.stats?.CpaRoasSales;
      if(!cpaRoasSales || !Array.isArray(cpaRoasSales)) return [];

      return [
        {
          name: "Sales",
          data: cpaRoasSales.filter(row => row?.sum__total_price !== null)
            .map(row => {
              return {x: row.date, y: row.sum__total_price};
            }),
          type: 'bar'
        },
        {
          name: "Ad Cost",
          data: cpaRoasSales.filter(row => row?.ad_cost !== null)
            .map(row => {
              return {x: row.date, y: row.ad_cost};
            }),
          type: 'line'
        }
      ];
    },

    chartOptions() {
      const labelColor = getCSSVariableValue("--bs-gray-500");
      const borderColor = getCSSVariableValue("--bs-gray-200");
      const baseColor = getCSSVariableValue("--bs-primary");
      const secondaryColor = getCSSVariableValue("--bs-gray-300");

      return {
        chart: {
          type: 'line',
          fontFamily: "inherit",
          toolbar: {
            show: false
          }
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '50%',
            endingShape: "rounded"
          }
        },
        fill: {
          opacity: 1
        },
        xaxis: {
          type: 'datetime',
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          labels: {
            // todo: format as "Month Day" i.e. July 9
            // formatter: function(value, timestamp, opts) {
            //   return opts.dateFormatter(new Date(timestamp)).format("MMM dd")
            // },
            style: {
              colors: labelColor,
              fontSize: "12px"
            }
          }
        },
        yaxis: [
          {
            title: 'Sales',
            labels: {
              formatter: (val) => Filters.shortMoney(val),
              style: {
                colors: labelColor,
                fontSize: "12px"
              }
            },
          },
          {
            title: 'Ad Cost',
            opposite: true,
            labels: {
              formatter: (val) => Filters.percent(val, 0),
              style: {
                colors: labelColor,
                fontSize: "12px"
              }
            }
          }],
        stroke: {
          curve: 'smooth'
        }
      };
    },

  },

});
</script>
