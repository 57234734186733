<template>
  <b-overlay :show="statsAreFetching" rounded="sm">
  <div :class="widgetClasses" class="card">
    <div class="card-header border-0 pt-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label fw-bolder fs-3 mb-1">Stats by Product</span>
        <span class="text-muted mt-1 fw-bold fs-7">Top 10 products</span>
      </h3>
      <div class="card-toolbar">
      </div>
    </div>

    <div class="card-body py-3">
      <div class="table-responsive">
        <table class="table align-middle gs-0 gy-4">
          <thead>
          <tr class="fw-bolder text-muted">
            <th class="min-w-325px rounded-start">Product</th>
            <th class="min-w-125px">Spend</th>
            <th class="min-w-125px">Sales</th>
            <th class="min-w-125px">Profit</th>
            <th class="min-w-125px">Spend 7 Days</th>
            <th class="min-w-125px">Profit 7 Days</th>
            <th class="min-w-125px">Spend 30 Days</th>
            <th class="min-w-125px">Profit 30 Days</th>
          </tr>
          </thead>

          <tbody>
          <template v-for="(item, index) in list" :key="index">
            <tr>
              <td>
                <div class="d-flex align-items-center">
                  <div class="symbol symbol-50px me-5">
                    <img :src="item.product.image" class="" alt="" />
                  </div>

                  <div class="d-flex justify-content-start flex-column">
                    <span
                      href="#"
                      class="text-dark fw-bolder text-hover-muted mb-1 fs-6"
                    >{{ item.product.title }}</span
                    >
                    <span
                      class="text-muted fw-bold text-muted d-block fs-7"
                    >{{ item.product.type }}</span
                    >
                  </div>
                </div>
              </td>

              <td>
                <span
                  href="#"
                  class="text-gray-700 fw-bolder text-hover-muted d-block mb-1 fs-6"
                >{{ $filters.money(item.spend, 2) }}</span>
              </td>

              <td>
                <span
                  href="#"
                  class="text-gray-700 fw-bolder text-hover-muted d-block mb-1 fs-6"
                >{{ $filters.money(item.sales, 2) }}</span>
              </td>

              <td>
                <span
                  href="#"
                  class="text-gray-700 fw-bolder text-hover-muted d-block mb-1 fs-6"
                >{{ $filters.money(item.profit, 2) }}</span>
              </td>

              <td>
                <span
                  href="#"
                  class="text-gray-700 fw-bolder text-hover-muted d-block mb-1 fs-6"
                >{{ $filters.money(item.spend_l7d, 2) }}</span>
              </td>

              <td>
                <span
                  href="#"
                  class="text-gray-700 fw-bolder text-hover-muted d-block mb-1 fs-6"
                >{{ $filters.money(item.profit_l7d, 2) }}</span>
              </td>

              <td>
                <span
                  href="#"
                  class="text-gray-700 fw-bolder text-hover-muted d-block mb-1 fs-6"
                >{{ $filters.money(item.spend_l30d, 2) }}</span>
              </td>

              <td>
                <span
                  href="#"
                  class="text-gray-700 fw-bolder text-hover-muted d-block mb-1 fs-6"
                >{{ $filters.money(item.profit_l30d, 2) }}</span>
              </td>

            </tr>
          </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  </b-overlay>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import {mapGetters} from "vuex";

export default defineComponent({
  name: "ProductsTable",
  components: {},
  props: {
    widgetClasses: String
  },

  computed: {

    ...mapGetters({
      stats: 'shopify/shops/dashboard/stats',
      statsAreFetching: 'shopify/shops/dashboard/statsAreFetching',
    }),

    list() {
      const productsData = this?.stats?.Product;
      if(!productsData || !Array.isArray(productsData)) return [];

      // {"shopify_shop_id":3,"product_id":64448653,"title":"Rerum Eos","featured_image_url":"https://via.placeholder.com/300x300.png/0099cc?text=dogs+non","spend_today":"0","sales_today":"0","profit_today":"0","spend_l7d":"147.69","profit_l7d":"0","spend_l30d":"147.69","profit_l30d":"0"}

      return productsData
        .map(function (row) {
          return {
            product: {
              id: row.product_id,
              image: row.featured_image_url,
              title: row.title,
              type: row.product_type,
            },
            spend: row.spend_today,
            sales: row.sales_today,
            profit: row.profit_today,
            spend_l7d: row.spend_l7d,
            profit_l7d: row.profit_l7d,
            spend_l30d: row.spend_l30d,
            profit_l30d: row.profit_l30d,
          }
        });

      return [
        {
          "product": {
            "image": "http://dummyimage.com/300x200.png/dddddd/000000",
            "title": "3-OAK MIX, BLACK/BLACKJACK/POST POLLEN",
            "type": "940"
          },
          "spend": 7.7,
          "sales": 94.69,
          "profit": 59.84,
          "spend_l7d": 23.38,
          "profit_l7d": 108.85,
          "spend_l30d": 1177.2,
          "profit_l30d": 1789.5
        },
        {
          "product": {
            "image": "http://dummyimage.com/300x200.png/ff4444/ffffff",
            "title": "BABY SUNSCREEN",
            "type": "Cougar"
          },
          "spend": 96.67,
          "sales": 46.5,
          "profit": 42.1,
          "spend_l7d": 285.6,
          "profit_l7d": 270.06,
          "spend_l30d": 1233.3,
          "profit_l30d": 2063.4
        },
        {
          "product": {
            "image": "http://dummyimage.com/300x200.png/dddddd/000000",
            "title": "Standardized Kentucky Blue (June) Grass",
            "type": "Bonneville"
          },
          "spend": 66.77,
          "sales": 73.21,
          "profit": 19.15,
          "spend_l7d": 88.97,
          "profit_l7d": 489.3,
          "spend_l30d": 711.9,
          "profit_l30d": 2572.5
        },
        {
          "product": {
            "image": "http://dummyimage.com/300x200.png/cc0000/ffffff",
            "title": "Formica Apis",
            "type": "Ram Van 1500"
          },
          "spend": 7.39,
          "sales": 19.53,
          "profit": 22.61,
          "spend_l7d": 400.47,
          "profit_l7d": 375.2,
          "spend_l30d": 368.7,
          "profit_l30d": 1211.4
        },
        {
          "product": {
            "image": "http://dummyimage.com/300x200.png/ff4444/ffffff",
            "title": "Virotox WN",
            "type": "Cherokee"
          },
          "spend": 48.77,
          "sales": 7.98,
          "profit": 52.76,
          "spend_l7d": 379.26,
          "profit_l7d": 87.99,
          "spend_l30d": 1407.0,
          "profit_l30d": 1791.9
        },
        {
          "product": {
            "image": "http://dummyimage.com/300x200.png/5fa2dd/ffffff",
            "title": "Heparin Sodium in Dextrose",
            "type": "Town & Country"
          },
          "spend": 9.05,
          "sales": 56.11,
          "profit": 2.43,
          "spend_l7d": 617.19,
          "profit_l7d": 680.54,
          "spend_l30d": 2653.2,
          "profit_l30d": 723.6
        },
        {
          "product": {
            "image": "http://dummyimage.com/300x200.png/ff4444/ffffff",
            "title": "Levothyroxine Sodium",
            "type": "Trooper"
          },
          "spend": 14.6,
          "sales": 37.83,
          "profit": 35.85,
          "spend_l7d": 644.42,
          "profit_l7d": 561.4,
          "spend_l30d": 2501.7,
          "profit_l30d": 2317.2
        },
        {
          "product": {
            "image": "http://dummyimage.com/300x200.png/5fa2dd/ffffff",
            "title": "Suphedrine PE",
            "type": "Sonata"
          },
          "spend": 29.99,
          "sales": 59.14,
          "profit": 70.9,
          "spend_l7d": 566.65,
          "profit_l7d": 550.83,
          "spend_l30d": 416.7,
          "profit_l30d": 1263.0
        },
        {
          "product": {
            "image": "http://dummyimage.com/300x200.png/ff4444/ffffff",
            "title": "MANGO BUTTER LIPSTICK",
            "type": "Suburban 2500"
          },
          "spend": 63.57,
          "sales": 2.68,
          "profit": 18.02,
          "spend_l7d": 554.33,
          "profit_l7d": 47.81,
          "spend_l30d": 2908.8,
          "profit_l30d": 2342.1
        },
        {
          "product": {
            "image": "http://dummyimage.com/300x200.png/cc0000/ffffff",
            "title": "Reboost",
            "type": "Econoline E150"
          },
          "spend": 69.36,
          "sales": 33.94,
          "profit": 46.88,
          "spend_l7d": 283.85,
          "profit_l7d": 357.49,
          "spend_l30d": 1326.3,
          "profit_l30d": 1421.4
        }
      ];
    },
  },

});
</script>
